import React from "react";

import { Container } from "@util/standard";
import { AppLayout, OrdersGrid } from "@shared/salesTool";
import { ParentWrapper } from "@shared/salesTool/components.styles";
import { useStore } from "@state/store";
import CreateCustomerForm from "@components/forms/createCustomer";

export default function EditCustomer() {
  const { selectedCustomer } = useStore();

  return (
    <AppLayout currentPath="editCustomer">
      <ParentWrapper margin="50px auto">
        <Container flexDirection="column">
          <CreateCustomerForm customer={selectedCustomer} />
        </Container>
        <OrdersGrid
          customerId={selectedCustomer?.id}
          title={`${selectedCustomer?.name}'s orders`}
        />
      </ParentWrapper>
    </AppLayout>
  );
}
